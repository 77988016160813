<template>
  <v-form v-model="valid">
    <LoadingContainer :loading="loading">
      <Alert :active="!!errorMessage.length">
        {{ errorMessage }}
      </Alert>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="clones.workcenter.title"
            :rules="titleRules"
            :counter="255"
            label="Work Center Name"
            required
            autofocus
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="clones.workcenter.facility_id"
            label="Facility"
            auto-select-first
            :items="facilities"
            :filter="facilitiesFilter"
            filled
            item-text="title"
            item-value="id"
            :rules="facilityRules"
            :disabled="!isNew || facilityIsSet"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="clones.workcenter.active"
            label="Active"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="redirect">Cancel</v-btn>
        <v-btn color="primary" @click="() => save()" :disabled="!valid">
          <v-icon>mdi-content-save</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </LoadingContainer>
  </v-form>
</template>

<script>
import Alert from "./Alert.vue";
import { ref, computed } from "vue-demi";
import { handleClones } from "feathers-pinia";
import rules from "../utils/rules";
import { useWorkcenters } from "@/store/workcenters.pinia";
import LoadingContainer from "./LoadingContainer.vue";

export default {
  name: "WorkcentersEditForm",
  components: {
    LoadingContainer,
    Alert,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    workcenter: {
      type: Object,
      default: () => ({}),
    },
    facilities: {
      type: Array,
      default: () => [],
    },
    facilityId: {
      default: null,
    },
  },
  setup(props, context) {
    const workcentersStore = useWorkcenters();
    /**
     * TODO: Add saved/error alerts
     */
    const valid = ref(false);
    const errorMessage = ref("");

    let { clones, saveHandlers } = handleClones(props);
    const { save_workcenter } = saveHandlers;

    const isNew = computed(() => {
      return !props.workcenter?.id && !props.workcenter?.id?.length;
    });

    const facilityIsSet = computed(() => {
      return !!props.workcenter?.facility_id;
    });

    const getRedirect = ({ workcenterId = null, facilityId = null }) => {
      return {
        name: !workcenterId ? "FacilityOverview" : "WorkcenterOverview",
        params: !workcenterId ? { facilityId } : { workcenterId },
      };
    };

    function redirect() {
      // Trigger save event without actually saving, and pass redirect params
      context.emit("saved", {
        redirect: getRedirect({
          facilityId: props.facilityId,
          workcenterId: props.workcenter.id,
        }),
      });
    }

    function save_item(shouldRedirect = true) {
      return save_workcenter().then((res) => {
        const redirect = getRedirect({
          facilityId: props.facilityId,
          workcenterId: res?.item?.id ?? null,
        });
        context.emit("saved", shouldRedirect ? { redirect } : null);
      });
    }

    function facilitiesFilter(item, queryText) {
      // Allow search by name or ID
      const textOne = item.title.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1 || queryText == item.id;
    }

    return {
      isNew,
      facilityIsSet,
      valid,
      titleRules: [
        rules.required,
        (v) =>
          workcentersStore.hasUniqueTitle(
            v,
            clones.workcenter.facility_id,
            clones.workcenter.id
          ) || "Title must be unique",
      ],
      facilityRules: [rules.required],
      errorMessage,
      clones,
      save_workcenter,
      facilitiesFilter,
      redirect,
      save: save_item,
    };
  },
};
</script>
