<template>
  <LoadingContainer :loading="isPending">
    <WorkcentersEditFormVue
      :loading="isPending"
      :workcenter="workcenter"
      :facilities="facilitiesStore.activeFacilities"
      :facilityId="facilityId"
      @saved="onSaved"
    />
  </LoadingContainer>
</template>

<script>
import { computed } from "vue-demi";
import { useGet } from "feathers-pinia";
// import Alert from "./Alert.vue";
import { useStore } from "@/store/app.pinia";
import { useFacilities } from "@/store/facilities.pinia";
import { useWorkcenters } from "@/store/workcenters.pinia";
import WorkcentersEditFormVue from "./WorkcentersEditForm.vue";
import LoadingContainer from "./LoadingContainer.vue";

export default {
  name: "WorkcentersEdit",
  components: {
    WorkcentersEditFormVue,
    LoadingContainer,
  },
  props: {
    id: {
      default: null,
    },
  },
  setup(props, { root }) {
    /**
     * TODO: Add error alerts
     */
    // Initiate stores
    const appStore = useStore();
    const facilitiesStore = useFacilities();
    const workcentersStore = useWorkcenters();

    const facilityId = computed(() => {
      return appStore.currentRoute.query.facilityId ?? null;
    });

    const createWorkcenter = (facilityId = null) => {
      const v = {};
      if (facilityId?.value) {
        v.facility_id = facilityId.value;
      }
      const item = new workcentersStore.Model(v);
      item.addToStore();
      return { item, isPending: item.isPending };
    };

    let { isPending } = props.id
      ? useGet({
          id: props.id,
          model: workcentersStore.Model,
        })
      : createWorkcenter();

    // // Manually computing item from store, because useGet bug with id reactivity
    const workcenter = computed(() =>
      props.id
        ? workcentersStore.Model.getFromStore(props.id) ?? null
        : createWorkcenter(facilityId).item
    );

    // onSaved callback
    const onSaved = (params = {}) => {
      if (params?.redirect) root.$router.push(params.redirect);
    };

    return {
      isPending,
      workcenter,
      facilitiesStore,
      onSaved,
      facilityId,
    };
  },
};
</script>

<style scoped></style>
