<template>
  <v-alert v-model="active" border="left" close-text="Close" :color="color">
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: "Alert",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "error",
    },
  },
};
</script>
